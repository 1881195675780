(function (w, o) {
  'use strict';

  var MEDIA_URL = '/assets/vendors/verint/v5/';

  OOo.getVars = function () {
    var cv = {
      sid: 'rackroomshoes',
      radius: 15,
      Loyalty_ID: o.readCookie('Cust_ID'),
      userDeviceId: o.readCookie('rrs_user_device_id'),
      feedbackType: 'waypoint',
    };
    if (
      sessionStorage.CTrecordingLink &&
      sessionStorage.CTuserID &&
      sessionStorage.CTpartition &&
      sessionStorage.CTguid
    ) {
      cv.clickTaleReplayLink = sessionStorage.CTrecordingLink;
      cv.clickTaleUID = sessionStorage.CTuserID;
      cv.clickTalePartition = sessionStorage.CTpartition;
      cv.clickTaleGUID = sessionStorage.CTguid;
    } else if (
      sessionStorage.CSreplayLink &&
      sessionStorage.CSid &&
      sessionStorage.CStagID
    ) {
      cv.contentsquareReplayLink = sessionStorage.CSreplayLink;
      cv.contentsquareIDs = sessionStorage.CSid;
      cv.contentsquareTagID = sessionStorage.CStagID;
    }
    return cv;
  };

  var OpinionLabInit = function () {
    o.oo_tab = new o.Ocode({
      tab: {
        title: 'Feedback',
        verbiage: 'Feedback',
        assetType: 'static',
        iconColor: 'black',
      },
      mobileTouches: 1,
      disableShow: true,
    });

    o.oo_waypoint = new o.Waypoint({
      pathToAssets: MEDIA_URL,
      assetType: 'custom',
      companyLogo: 'verint-rr-ob-logo.svg',
      categories: {
        website: {
          oCode: {
            customVariables: o.getVars(),
          },
          icon: 'verint-website-icon.svg',
          buttonText: 'Website',
        },
        store: {
          oCode: {
            commentCardUrl: 'https://location.opinionlab.com',
            referrerRewrite: {
              searchPattern: /:\/\/[^\/]*/, //
              replacePattern: '://store.rackroomshoes.com',
            },
            customVariables: o.getVars(),
          },
          icon: 'verint-store-icon.svg',
          buttonText: 'Store',
        },
      },
    });
    o.appendWaypoint('oo_tab');
    o.appendWaypointMobile('oo_tab', 2);
  };

  OpinionLabInit();

  var checkOOEngine = setInterval(function () {
    if (typeof OOo !== 'undefined') {
      clearInterval(checkOOEngine);
      if (
        typeof OOo !== 'undefined' &&
        typeof OOo.releaseDetails !== 'object'
      ) {
        OOo.releaseDetails = [];
      }
      OOo.releaseDetails.push({
        author: 'YW',
        timeStamp: '06/01/2022, 13:43:27',
        fileName: 'oo_waypoint.js',
        fileVersion: '2',
        ticketNumber: 'DYN-1230984',
      });
    }
  }, 5000);
})(window, OOo);
