var rrs_accordion = (function () {
  var settings = {
    // default settings
    // animation speed
    speed: 300, // close all other rrs_accordion items if true
    oneOpen: false,
  };
  return {
    // pass configurable object literal
    init: function ($settings) {
      var $rrs_accordion = $('.js-rrs_accordion');
      var $rrs_accordion_header = $rrs_accordion.find(
        '.js-rrs_accordion-header'
      );
      var $rrs_accordion_item = $('.js-rrs_accordion-item');

      $rrs_accordion_header.on('click', function () {
        console.log('click');
        rrs_accordion.toggle($(this));
      });
      $.extend(settings, $settings);
      // ensure only one rrs_accordion is active if oneOpen is true
      if (settings.oneOpen && $('.js-rrs_accordion-item.active').length > 1) {
        $('.js-rrs_accordion-item.active:not(:first)').removeClass('active');
      }
      // reveal the active rrs_accordion bodies
      $('.js-rrs_accordion-item.active')
        .find('> .js-rrs_accordion-body')
        .show();
    },
    toggle: function ($this) {
      if (
        settings.oneOpen &&
        $this[0] !=
          $this
            .closest('.js-rrs_accordion')
            .find(
              '> .js-rrs_accordion-item.active > .js-rrs_accordion-header'
            )[0]
      ) {
        $this
          .closest('.js-rrs_accordion')
          .find('> .js-rrs_accordion-item')
          .removeClass('active')
          .find('.js-rrs_accordion-body')
          .slideUp();
      }
      // show/hide the clicked rrs_accordion item
      $this.closest('.js-rrs_accordion-item').toggleClass('active');
      $this.next().stop().slideToggle(settings.speed);
    },
  };
})();

function scrolltargets(dataname) {
  function scrollanimate() {
    const currenttarget = this.getAttribute('data-' + dataname);

    document.querySelector(currenttarget).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    const currentParentActive = !document
      .querySelector(currenttarget)
      .parentNode.classList.contains('active');
    if (currentParentActive) {
      document.querySelector(currenttarget).click();
    }
  }

  const elements = document.querySelectorAll('[data-' + dataname + ']');
  elements.forEach(function (element) {
    element.addEventListener('click', scrollanimate);
  });
}
